<script setup></script>

<template>
    <div class="bg-image h-screen w-screen block md:grid md:grid-cols-2">
        <section
            class="md:col-start-2 flex flex-col items-center justify-center h-screen bg-white px-8 lg:px-16"
        >
            <div>
                <img
                    src="/img/logo.svg"
                    alt="numnum"
                    class="max-w-40 mx-auto mb-16"
                />
            </div>

            <slot name="header" />

            <div class="px-2 lg:px-0 xl:px-14 w-full">
                <slot name="form" />
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>
.bg-image {
    background-repeat: no-repeat;
    background-image: linear-gradient(
            90deg,
            rgba(84, 0, 207, 0.6) 0%,
            rgba(84, 0, 207, 0.6) 100%
        ),
        url("/img/numnum-registration-image.jpg");
    background-position: 50% 50%;
    background-color: #5400cf;
    background-size: cover, cover;
}
</style>
